<template>
  <ModalFormGeneric :id="COMPNAME" :title="$t('lgout.t')" :btnText="$t('lgout.b')" btnIcon="lgo"
      @submit="onSubmit"
      @reset="$emit('reset')"
      :ref="COMPNAME" />
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import { REST, baseLOGIN } from '@/components/RestCall';

export default {
  data() {
    return {
      COMPNAME: 'logoutWindow'
    }
  },
  computed: {
  },
  methods: {
    onSubmit() {
      REST.post(baseLOGIN.concat('/finish'))
        .then(resp => {
          this.$store.commit('showSuccess',resp.d.message);
        }).catch(() => {
          //this.$store.commit('showWarn',e.message); // just do the logout
        }).finally(() => {
          this.$store.commit('logout');
          this.$router.push('/').catch(() => {}); // redirect to landing page, ignore error if already there
        });
      this.$refs[this.COMPNAME].hide();
      this.$emit('submit');
    },
  },
  components: {
    ModalFormGeneric
  }
}
</script>